<script>
import { layoutMethods } from "@/state/helpers";
/**
 * Right-sidebar 
 */
export default {
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"],
      },
      layout: this.$store ? this.$store.state.layout.layoutType : {} || {},
      width: this.$store ? this.$store.state.layout.layoutWidth : {} || {},
      topbar: this.$store ? this.$store.state.layout.topbar : {} || {},
      sidebarType: this.$store
        ? this.$store.state.layout.leftSidebarType
        : {} || {},
      loader: this.$store ? this.$store.state.layout.loader : {} || {},
    };
  },
  methods: {
    ...layoutMethods,
    hide() {
      this.$parent.toggleRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      if (event.target.classList)
        return !event.target.classList.contains("toggle-right");
    },
    changeLayout(layout) {
      this.changeLayoutType({
        layoutType: layout,
      });
      this.saveStyle();
    },
    changeType(type) {
       this.changeLeftSidebarType({
        leftSidebarType: type,
      });
      this.saveStyle();
    },
    changeWidth(width) {
       this.changeLayoutWidth({
        layoutWidth: width,
      });
      this.saveStyle();
    },
    changeTopbartype(value) {
       this.changeTopbar({
        topbar: value,
      });
      this.saveStyle();
    },
    changeloader() {
       this.changeLoaderValue({
        loader: this.loader,
      });
        this.saveStyle();
    },
    saveStyle(){
      //保存样式
      let {layoutType,layoutWidth,leftSidebarType,loader,topbar} = this.$store.state.layout
      let style = {layoutType,layoutWidth,leftSidebarType,loader,topbar}
      let styleStr = JSON.stringify(style)
      localStorage.setItem("systemSetStyle",styleStr)
    }
  },
};
</script>

<template>
  <div>
    <!-- Right Sidebar -->
    <div v-click-outside="config" class="right-bar">
      <div data-simplebar class="h-100">
        <div class="rightbar-title px-3 py-4">
          <a
            href="javascript:void(0);"
            class="right-bar-toggle float-right"
            @click="hide"
          >
            <i class="mdi mdi-close noti-icon"></i>
          </a>
          <h5 class="m-0">设置</h5>
        </div>
        <div class="p-3">
          <h6 class="mb-0">布局</h6>
          <hr class="mt-1" />
          <b-form-radio-group
            v-model="layout"
            stacked
            @input="changeLayout($event)"
          >
            <b-form-radio value="vertical" class="mb-1">垂直</b-form-radio>
            <b-form-radio value="horizontal" class="mb-1"
              >水平</b-form-radio
            >
          </b-form-radio-group>
          <!-- Width -->
          <h6 class="mt-3">宽度</h6>
          <hr class="mt-1" />
          <b-form-radio-group
            v-model="width"
            stacked
            @input="changeWidth($event)"
          >
            <b-form-radio value="fluid" class="mb-1">不固定</b-form-radio>
            <b-form-radio value="boxed">盒装</b-form-radio>
          </b-form-radio-group>

          <!-- Sidebar -->
          <div v-if="layout === 'vertical'">
            <h6 class="mt-3">侧边栏</h6>
            <hr class="mt-1" />
            <b-form-radio-group
              v-model="sidebarType"
              stacked
              @input="changeType($event)"
            >
              <b-form-radio value="dark" class="mb-1">深色</b-form-radio>
              <b-form-radio value="light" class="mb-1">浅色</b-form-radio>
              <b-form-radio value="compact" class="mb-1">紧凑</b-form-radio>
              <b-form-radio value="icon" class="mb-1">图标</b-form-radio>
              <b-form-radio value="colored">彩色的</b-form-radio>
            </b-form-radio-group>
          </div>

          <!-- Topbar -->
          <div v-if="layout === 'horizontal'">
            <h6 class="mt-3">头部</h6>
            <hr class="mt-1" />
            <b-form-radio-group
              v-model="topbar"
              stacked
              @input="changeTopbartype($event)"
            >
              <b-form-radio value="dark" class="mb-1">深色</b-form-radio>
              <b-form-radio value="light" class="mb-1">浅色</b-form-radio>
            </b-form-radio-group>
          </div>

          <!-- Preloader -->
          <h6 class="mt-3">预加载</h6>
          <hr class="mt-1" />

          <b-form-checkbox
            v-model="loader"
            name="check-button"
            switch
            @input="changeloader"
            >预加载</b-form-checkbox
          >
        </div>
        <!-- Settings -->
        <hr class="mt-0" />
         <!--  <h6 class="text-center mb-0">选择布局</h6>

      <div class="p-4">
          <div class="mb-2">
            <router-link
              to="http://nazox.vuejs-light-h.themesdesign.in/"
              target="_blank"
            >
              <img
                src="@/assets/images/layouts/layout-4.jpg"
                class="img-fluid img-thumbnail"
                alt
              />
            </router-link>
          </div>

          <div class="mb-2">
            <router-link
              to="http://nazox.vuejs-dark-h.themesdesign.in/"
              target="_blank"
            >
              <img
                src="@/assets/images/layouts/layout-5.jpg"
                class="img-fluid img-thumbnail"
                alt
              />
            </router-link>
          </div>

          <div class="mb-2">
            <router-link
              to="http://nazox.vuejs-rtl-h.themesdesign.in/"
              target="_blank"
            >
              <img
                src="@/assets/images/layouts/layout-6.jpg"
                class="img-fluid img-thumbnail"
                alt
              />
            </router-link>
          </div>
        </div> -->
      </div>
      <!-- end slimscroll-menu-->
    </div>
    <!-- /Right-bar -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
  </div>
</template>